/* eslint-disable import/max-dependencies */
import React, {useState} from "react";
import Dayjs from "dayjs";
import {Delete} from "@mui/icons-material";
import {Box, FormHelperText, IconButton, Menu, MenuItem} from "@mui/material";
import {SetupCard, DatePickerForm} from "@fas/ui-core";
import {removeError} from "@fas/ui-framework";
import useErrorMessage from "./useErrorMessage";
import NumberTextField from "./NumberTextField";
import {useDispatch} from "../../store";
import type {Adjustment} from "./types";
import { useFormField } from "../../components/Form/Form";
import { formatDateUTC } from "../../helpers";

const staticOptions: { label: string, value: keyof Adjustment }[] = [
  {
    label: "Date",
    value: "date",
  },
];

const options: { label: string, value: keyof Adjustment }[] = [
  {
    label: "Commission",
    value: "payoutAmount",
  },
  {
    label: "Shave",
    value: "shaveAmount",
  },
  {
    label: "Commission for firsts %",
    value: "payoutAmountFirst",
  },
  {
    label: "Commission for repeats %",
    value: "payoutAmountRepeats",
  },
];

type RowProps = { name: string, label: string, onRemove: () => void };

const views = [
  "year",
  "day",
  "hours",
  "minutes",
];

function DateComponent({ name, label }: { name: string, label: string }) {
  const {value} = useFormField<string>(name);
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" title={`utc=${formatDateUTC(value)}`}>
      <DatePickerForm
        minDate={Dayjs(Date.now())}
        minDateTime={Dayjs(Date.now())}
        name={name}
        label={label}
        format="YYYY-MM-DDTHH:mm:00.000Z"
        inputFormat="YYYY-MM-DD HH:mm"
        mask="____-__-__ __:__"
        views={views}
      />
    </Box>
  );
}

const rowMap: {[k: string]: React.FC<RowProps>} = {
  date: DateComponent,
};

const defaultRow = ({name, label, onRemove}: RowProps) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    <NumberTextField
      name={name}
      label={label}
      type="number"
    />
    <IconButton onClick={onRemove} data-testid="deleteButton">
      <Delete/>
    </IconButton>
  </Box>
);

type Props = {
  value: Adjustment,
  index: number,
  update: (index: number, o: Adjustment) => void,
  remove: (index: number) => void,
}

function SubrateAdjustment({ value, index, update, remove}: Props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const fields = staticOptions.concat(options.filter(field => Object.keys(value).some((k) => k === field.value)));

  function handleChoose(item: { label: string; value: string }) {
    update(index, { ...value, [item.value]: null });
    setAnchorEl(null);
  }
  const errorMessage = useErrorMessage(`adjustments[${index}]`);
  function clearError(key: string) {
    dispatch(removeError([key]));
  }


  return (
    <Box width={1} sx={{ "& .MuiPaper-root": {border: `1px solid ${errorMessage ? "red" : "transparent" }`} }}>
      <SetupCard
        title={`№ ${index + 1}`}
        showMainBtn
        mainBtnText="Add subrate option"
        onMainBtnClick={(e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
        showSecondaryBtn
        secondaryBtnText="Remove rule"
        onSecondaryBtnClick={() => {
          remove(index);
          clearError(`adjustments[${index}]`);
          fields.forEach((field) => clearError(`adjustments[${index}].${field.value}`));
        }}
      >
        <Box width={1}>
          {fields.map((field) => {
            const Row: React.FC<RowProps> = rowMap[field.value] || defaultRow;
            return (
              <Row
                key={field.value}
                name={`adjustments[${index}].${field.value}`}
                label={field.label}
                onRemove={() => {
                  const v = {...value};
                  delete v[field.value];
                  clearError(`adjustments[${index}].${field.value}`);
                  update(index, v);
                }}
              />
            );
          })}
          <FormHelperText error={!!errorMessage}>
            {errorMessage}
          </FormHelperText>
        </Box>
      </SetupCard>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {options.map((item) => (
          <MenuItem
            key={item.value}
            disabled={fields.some((field) => field.value === item.value)}
            onClick={() => handleChoose(item)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default SubrateAdjustment;
